import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from 'clsx';
import {Link} from "@material-ui/core";
import useGetBeiAnData from "../../services/useGetBeiAnData";

const CURRENT_YEAR = new Date().getFullYear();

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    padding: theme.spacing(1),
    marginTop: 'auto'
  },
  itemGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: theme.spacing(-1)
  },
  copyright: {
    color: 'rgba(255, 255, 255, 1)',
    fontSize: theme.typography.caption.fontSize
  },
  item: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: {
    color: 'rgba(255, 255, 255, 1)',
    fontSize: theme.typography.caption.fontSize,
    '&:hover': {
      color: theme.palette.secondary.light
    }
  }
}));

const Footer = () => {
  const classes = useStyles();
  const {
    text,
    link
  } = useGetBeiAnData();

  return (
    <footer className={classes.root}>
      <div className={classes.itemGroup}>
        <div className={clsx(classes.copyright, classes.item)}>
          Copyright &copy; 2020-{CURRENT_YEAR} CIT-CoPT
        </div>
        <div className={classes.item}>
          <Link href={link} target={'_blank'} rel={'noreferrer noopener'} className={classes.link}>
            {text}
          </Link>
        </div>
      </div>
    </footer>
  )
};

export default Footer
