import {useMemo} from "react";

enum Site {
  'zdy',
  'hust'
}

const host = {
  [Site.zdy]: 'zdydatabase',
  [Site.hust]: 'unioncitcopt-hust'
}

const beiAn = {
  [Site.zdy]: '鄂ICP备2020017655号-1',
  [Site.hust]: '鄂ICP备2020017655号-2'
}

const link = 'https://beian.miit.gov.cn/';

const useGetBeiAnData = () => {
  return useMemo(() => {
    const origin = window?.location?.origin;
    let text = beiAn[Site.hust];
    if (origin && origin.match(new RegExp(host[Site.zdy], 'ig'))) {
      text = beiAn[Site.zdy];
    }

    return {
      text,
      link
    }
  }, [])
}

export default useGetBeiAnData
